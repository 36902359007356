












































import Vue from 'vue';
import Component from 'vue-class-component';
import VuePapaParse from 'vue-papa-parse';
import API, { Types } from '@/modules/API';
import { Props, Global, User } from '@/store';

Vue.use(VuePapaParse);

@Component({})

export default class BulkUploader extends Vue {
    @Props.Action('editProp') editProp;
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;
    @User.State('project') project;
    @User.State('role') role;

    parsedJson = [];
    isBtnDisabled = true;
    fileName = '';
    show = false;
    message = '';
    uploading = false;
    deviceClassId = null;

    get headers() {
        return [
            {text: this.$t('Name'), value: 'name', sortable: false, class: this.lang === 'en' ? '' : 'text-xs-right' },
            {text: this.$t('lat'), value: 'lat', sortable: false, class: this.lang === 'en' ? '' : 'text-xs-right' },
            {text: this.$t('lng'), value: 'lng', sortable: false, class: this.lang === 'en' ? '' : 'text-xs-right' },
            {text: this.$t('meta.type'), value: 'meta.type', sortable: false, class: this.lang === 'en' ? '' : 'text-xs-right' }
        ];
    }

    async mounted() {
        this.setPageTitle('Bulk Uploader');
        const result = await API.get(Types.COMPANIES, `${this.project.company}/deviceclasses`);
        const deviceClasses = result ? result.results : null;
        const deviceClass = deviceClasses ? deviceClasses.find((el) => el.name === 'asset') : null;
        this.deviceClassId = deviceClass ? deviceClass.id : null;
    }

    removeFile() {
        this.isBtnDisabled = true;
        this.parsedJson = [];
        this.fileName = '';
        this.show = false;
    }

    onPickFile() {
        // @ts-ignore
        this.$refs.fileInput.click();
    }

    readFile(event) {
        const file = event.target.files[0];
        this.fileName = file.name;
        const fileType = file.type;
        if (fileType.match('application/vnd.ms-excel')) {  
            this.show = false;
            // @ts-ignore
            this.$papa.parse(file, {
                skipEmptyLines: true,
                header: true,
                complete: (results) => {
                    this.parsedJson = results.data;
                    this.isBtnDisabled = false;
                }
            });
        }
        else { 
            this.show = true;
            this.message = 'This is not a csv file' ;
            this.isBtnDisabled = true;
            this.parsedJson = [];
        }
        event.target.value = '';
    }
    
    async upload() {
        this.uploading = true;
        if (!this.deviceClassId) {
            this.$notify({
                type: 'error',
                title: 'Error!',
                text: this.$t('id of device class asset is required')
            });
            this.uploading = false;
            this.isBtnDisabled = true;
            this.parsedJson = [];
            this.fileName = '';
            return;
        }
        if (!this.project.id) {
            this.$notify({
                type: 'error',
                title: 'Error!',
                text: this.$t('project id is required')
            });
            this.uploading = false;
            this.isBtnDisabled = true;
            this.parsedJson = [];
            this.fileName = '';
            return;
        }
        await Promise.all(this.parsedJson.map(async (row) => {
            if (!row['name']) {
                this.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: this.$t('name is required')
                });
                return;
            }
            const body = {
                name: row['name'],
                project: this.project.id,
                deviceclass: this.deviceClassId
            };
            // create asset in cloud
            const res = await API.post(
                Types.PROJECTS,
                this.project.id + '/devices/',
                body,
                {}
            );
            const deviceId = res.id ? res.id : null;
            
            if (deviceId) {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: this.$t(`Device ${row['name']} successfully created`)
                });
                
                if (row['meta.type']) {
                    // edit meta.type prop
                    this.editProp({
                        _notify_msg: null,
                        name: 'meta.type',
                        objectID: deviceId,
                        objectType: 'devices',
                        value: { type: row['meta.type']}
                    });
                }
             
                if (row['lat'] && row['lng']) {
                    // edit meta.location prop
                    this.editProp({
                        _notify_msg: null,
                        name: 'meta.location',
                        objectID: deviceId,
                        objectType: 'devices',
                        value: { lat: parseFloat(row['lat']), lng: parseFloat(row['lng']) }
                    });
                }
            }
            else {
                this.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: this.$t('Device is not created')
                });
            }
        }));
        this.uploading = false;
        this.isBtnDisabled = true;
        this.parsedJson = [];
        this.fileName = '';
    }
}
